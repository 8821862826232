<template>
  <article class="w-full h-32 sm:h-64 p-4  flex flex-col sm:flex-row">
    <div class="rounded-md w-full h-56 sm:w-80 sm:h-56 cursor-pointer">
      <router-link :to="{ name: 'Content', params: { path: pathurl } }">
        <img :src="img" class="object-cover w-full h-full rounded-md" />
      </router-link>
    </div>
    <div
      class="w-full sm:w-[calc(100%-21.5rem)] mt-2 ml-1 sm:ml-5 h-full  flex flex-col"
    >
      <router-link :to="{ name: 'Content', params: { path: pathurl } }">
        <h1
          class="font-hslBold text-lg text-black cursor-pointer dark:text-white  sm:text-3xl "
        >
          {{ title }}
        </h1>
      </router-link>
      <div class="w-full mt-1  sm:mt-4 h-auto ">
        <p
          class=" text-sm sm:text-xl select-none dark:text-hslgray text-gray-500 font-hsl articlebox-textmobile sm:articlebox-text whites"
        >
          {{ description }}
        </p>
      </div>
      <div
        class="w-full h-full mt-2 sm:mt-3 flex items-end pb-2  justify-between"
      >
        <div class="flex justify-center items-center ">
          <MdCalendarIcon
            class="text-lg sm:text-2xl text-black dark:fill-current text-white"
          />
          <ion-icon
            class=" text-lg sm:text-2xl text-black dark:text-white"
            name="calendar-outline"
          ></ion-icon>
          <span
            class="text-black text-sm font-hslMedium ml-2 dark:text-white"
            >{{ moment(date).format("MMM DD YYYY") }}</span
          >
        </div>
        <div>
          <router-link :to="{ name: 'Content', params: { path: pathurl } }">
            <span class="flex items-center justify-center cursor-pointer">
              <span
                class="text-base sm:text-xl text-black dark:text-white font-hslBold mr-2"
                >Read more</span
              >
              <MdArrowRoundForwardIcon
                class="textxl sm:text-2xl text-black dark:fill-current text-white"
              />
              <ion-icon
                class="textxl sm:text-2xl text-black dark:text-white"
                name="arrow-forward-outline"
              ></ion-icon>
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import moment from "moment";
import MdArrowRoundForwardIcon from "vue-ionicons/dist/md-arrow-round-forward.vue";
import MdCalendarIcon from "vue-ionicons/dist/ios-calendar.vue";
export default {
  data() {
    return {
      moment: null,
    };
  },
  components: {
    MdArrowRoundForwardIcon,
    MdCalendarIcon,
  },
  created() {
    this.moment = moment;
  },
  props: ["img", "description", "title", "pathurl", "date"],
};
</script>
