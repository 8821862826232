<template>
  <main class="bg-white dark:bg-black h-screen ">
    <teleport to="head">
      <meta name="title" content="Blog - Hasolu" />
      <meta
        name="description"
        content="
Articles on technology and software"
      />
      <meta name="robots" content="index, follow" />

      <meta name="language" content="English" />
      <meta name="author" content="hasolu" />
    </teleport>
    <Header
      @scroll="updateScroll"
      :class="scrollPosition > 10 ? 'border-b  border-alphagray h-16' : null"
      class="fixed bg-white dark:bg-black transition-all duration-150 z-10 sm:dark:bg-transparent sm:border-none sm:bg-transparent top-0 "
      title="Blog"
    />

    <div
      class=" container mt-24 sm:mt-36  mx-auto  items-center h-screenp-4 space-y-4"
    >
      <div class=" w-full sm:w-3/4 mx-auto  h-screen ">
        <span v-if="loading" class="space-y-56  sm:space-y-4">
          <Loader v-for="i in 3" :key="i" />
        </span>

        <span v-if="!loading" class="space-y-56  sm:space-y-4">
          <articlebox
            v-for="(i, index) in articles"
            :key="index"
            :img="i.img"
            :description="i.description"
            :title="i.title"
            :pathurl="i.path"
            :date="i.date"
          />
        </span>
      </div>
    </div>
  </main>
</template>
<script>
import Header from "@/components/Header";
import axios from "axios";
import Articlebox from "@/components/Articlebox";
import Loader from "@/components/Loader";

export default {
  data() {
    return {
      articles: [],
      loading: true,
      scrollPosition: null,
    };
  },
  mounted() {
    this.fetchData();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    async fetchData() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/articles`, {
          headers: { "api-token": process.env.VUE_APP_API_TOKEN },
        })
        .then((res) => {
          this.articles = res.data;
        });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },

  components: { Header, Loader, Articlebox },
};
</script>
