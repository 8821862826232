<template>
  <article class="w-full h-32 sm:h-64 p-4  flex flex-col sm:flex-row">
    <div class="rounded-md w-full h-56 sm:w-80 ">
      <Skeletor
        width="100%"
        class="rounded-md dark:bg-shimmer"
        height="14rem"
      />
    </div>
    <div
      class="w-full sm:w-[calc(100%-21.5rem)] mt-2  sm:ml-5 h-full  flex flex-col"
    >
      <Skeletor
        width="100%"
        class="rounded-md dark:bg-shimmer"
        :height="innerw > 640 ? '50%' : '100%'"
      />
      <div class="w-full mt-1  sm:mt-4 h-auto space-y-2 ">
        <Skeletor
          width="100%"
          class="rounded-md dark:bg-shimmer"
          :height="innerw > 640 ? '100%' : '15px'"
        />
        <Skeletor
          width="100%"
          class="rounded-md dark:bg-shimmer"
          :height="innerw > 640 ? '100%' : '15px'"
        />
        <Skeletor
          v-if="innerw > 640"
          width="100%"
          class="rounded-md dark:bg-shimmer"
          height="100%"
        />
      </div>
      <div
        class="w-full h-full mt-2 sm:mt-3  items-end pb-2 flex  justify-between"
      >
        <div class=" w-32 h-8 ">
          <Skeletor
            width="100%"
            class="rounded-md dark:bg-shimmer"
            height="100%"
          />
        </div>
        <div class="w-32 h-8">
          <Skeletor
            width="100%"
            class="rounded-md dark:bg-shimmer"
            height="100%"
          />
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { Skeletor } from "vue-skeletor";

export default {
  data() {
    return {
      innerw: null,
    };
  },
  components: {
    Skeletor,
  },
  mounted() {
    this.innerw = window.innerWidth;
  },
};
</script>
